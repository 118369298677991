<template>
  <b-modal
    id="modal-tenant"
    ref="refModal"
    :title="t('Khách hàng')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="itemLocal.individual"
                name="individual"
                :value="true"
                class="custom-control-primary"
              >
                {{ t('Cá nhân') }}
              </b-form-radio>

              <b-form-radio
                v-model="itemLocal.individual"
                name="individual"
                :value="false"
                class="custom-control-primary"
              >
                {{ t('Tổ chức') }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{
                itemLocal.individual === true
                  ? t('Thông tin cá nhân')
                  : t('Thông tin tổ chức')
              }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="3">
            <validation-provider
              #default="validationContext"
              name="file-input-avatar"
              :rules="isTingTong && !isAddLead && !itemLocal.avatar ? 'required' : ''"
            >
              <image-view
                id="file-input-avatar"
                v-model="itemLocal.avatarFile"
                :required="isTingTong && !isAddLead"
                :src="itemLocal.avatar"
                :label="t('Ảnh đại diện')"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="file-input-id-number-front"
              :rules="isTingTong && !isAddLead && !itemLocal.idNumberFrontImage ? 'required' : ''"
            >
              <image-view
                id="file-input-id-number-front"
                v-model="itemLocal.idNumberFrontImageFile"
                :required="isTingTong && !isAddLead"
                :src="itemLocal.idNumberFrontImage"
                :label="t('CCCD mặt trước')"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="file-input-id-number-back"
              :rules="isTingTong && !isAddLead && !itemLocal.idNumberBackImage ? 'required' : ''"
            >
              <image-view
                id="file-input-id-number-back"
                v-model="itemLocal.idNumberBackImageFile"
                :required="isTingTong && !isAddLead"
                :src="itemLocal.idNumberBackImage"
                :label="t('CCCD mặt sau')"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <image-view
              id="file-input-passport"
              v-model="itemLocal.passportImageFile"
              :src="itemLocal.passportImage"
              :label="t('Hộ chiếu')"
              :instruction="null"
            />
          </b-col>

          <b-col
            v-if="!itemLocal.individual"
            cols="3"
          >
            <image-view
              id="file-input-certificate"
              v-model="itemLocal.certificateOfBusinessRegistrationImageFile"
              :src="itemLocal.certificateOfBusinessRegistrationImage"
              :label="t('Đăng ký kinh doanh')"
              :instruction="null"
            />
          </b-col>
        </b-row>

        <div v-if="itemLocal.individual === true">

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="t('Họ và tên')"
                >
                  <template
                    v-slot:label
                  >
                    {{ t('Họ và tên') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Nguyễn Nam Phong"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  :label="t('Số điện thoại')"
                >
                  <template
                    v-slot:label
                  >
                    {{ t('Số điện thoại') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                :rules="isTingTong ? 'email' : 'email'"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Email
                  </template>
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="birthday"
                :rules="isTingTong && !isAddLead ? 'required' : ''"
              >
                <select-date
                  v-model="itemLocal.birthday"
                  :required="isTingTong && !isAddLead"
                  :default-value="itemLocal.birthday"
                  :label="t('Ngày sinh')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="gender"
                :rules="isTingTong && !isAddLead ? 'required' : ''"
              >
                <select-gender
                  v-model="itemLocal.gender"
                  :required="isTingTong && !isAddLead"
                  :default-value="itemLocal.gender"
                  :label="t('Giới tính')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="idNumber"
                :rules="isTingTong && !isAddLead ? 'required' : ''"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('CMND/CCCD') }}
                    <span
                      v-if="isTingTong && !isAddLead"
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="idNumber"
                    v-model="itemLocal.idNumber"
                    placeholder="174850033"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="issueDate"
                :rules="isTingTong && !isAddLead ? 'required' : ''"
              >
                <select-date
                  v-model="itemLocal.issueDate"
                  :required="isTingTong && !isAddLead"
                  :default-value="itemLocal.issueDate"
                  :label="t('Ngày cấp')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="placeOfIssue"
                :rules="isTingTong && !isAddLead ? 'required' : ''"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Nơi cấp CMND/CCCD') }}
                    <span
                      v-if="isTingTong && !isAddLead"
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="placeOfIssue"
                    v-model="itemLocal.placeOfIssue"
                    :placeholder="t('Cục cảnh sát')"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <select-province
                v-model="itemLocal.province"
                :default-value="itemLocal.province"
              />
            </b-col>
            <b-col md="6">
              <select-district
                v-model="itemLocal.district"
                :default-value="itemLocal.district"
                :province="itemLocal.province"
              />
            </b-col>
            <b-col md="6">
              <select-ward
                v-model="itemLocal.ward"
                :district="itemLocal.district"
                :default-value="itemLocal.ward"
              />
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Địa chỉ chi tiết')"
              >
                <b-form-input
                  id="address"
                  v-model="itemLocal.address"
                  :placeholder="t('91 Nguyễn Chí Thanh')"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isVmarket">
            <b-col>
              <b-form-group
                :label="t('Chỗ ở hiện tại')"
              >
                <b-form-input
                  id="currentAddress"
                  v-model="itemLocal.currentAddress"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="t('Ngành hàng kinh doanh')"
              >
                <b-form-input
                  id="businessDomain"
                  v-model="itemLocal.businessDomain"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="t('Số tài khoản')"
              >
                <b-form-input
                  id="bankAccountNumber"
                  v-model="itemLocal.bankAccountNumber"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Ngân hàng')"
              >
                <b-form-input
                  id="bankAccountName"
                  v-model="itemLocal.bankAccountName"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="t('Nghề nghiệp')"
              >
                <b-form-input
                  id="job"
                  v-model="itemLocal.job"
                  :placeholder="t('Sinh viên')"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Nơi làm việc')"
              >
                <b-form-input
                  id="workplace"
                  v-model="itemLocal.workplace"
                  :placeholder="t('Đại học Bách Khoa')"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('Người liên lạc')"
              >
                <b-form-input
                  id="contactPersonName"
                  v-model="itemLocal.contactPersonName"
                  :placeholder="t('Nguyễn Văn A')"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('SĐT người liên lạc')"
              >
                <b-form-input
                  id="contactPersonPhone"
                  v-model="itemLocal.contactPersonPhone"
                  placeholder="0868123456"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                :label="t('Mã vân tay cửa ra vào')"
              >
                <b-form-input
                  id="fingerPrintCode"
                  v-model="itemLocal.fingerPrintCode"
                  placeholder="68"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="itemLocal.foreign"
                  name="check-button"
                  switch
                  inline
                >
                  {{ t('Khách nước ngoài') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="itemLocal.foreign === true">
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Quốc tịch"
                rules=""
              >
                <select-country
                  v-model="itemLocal.country"
                  :default-value="itemLocal.country"
                  :label="t('Quốc tịch')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="passportNumber"
                rules=""
              >
                <b-form-group
                  :label="t('Số hộ chiếu')"
                >
                  <b-form-input
                    id="passportNumber"
                    v-model="itemLocal.passportNumber"
                    placeholder="C5006886"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="passportType"
                rules=""
              >
                <b-form-group
                  :label="t('Loại hộ chiếu')"
                >
                  <b-form-input
                    id="passportType"
                    v-model="itemLocal.passportType"
                    placeholder="P"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Ngày hết hạn"
                :rules="isTingTong && !isAddLead ? 'required': ''"
              >
                <select-date
                  v-model="itemLocal.dateOfExpiry"
                  :required="isTingTong && !isAddLead"
                  :default-value="itemLocal.dateOfExpiry"
                  :label="t('Ngày hết hạn')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Company -->
        <div v-else>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="t('Tên công ty/tổ chức')"
                >
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  :label="t('Số điện thoại')"
                >
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                rules="email"
              >
                <b-form-group
                  label="Email"
                >
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="businessCode"
                rules=""
              >
                <b-form-group
                  :label="t('Mã số doanh nghiệp')"
                >
                  <b-form-input
                    id="businessCode"
                    v-model="itemLocal.businessCode"
                    placeholder="0109154368"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="t('Người đại diện')"
              >
                <b-form-input
                  id="contactPersonName"
                  v-model="itemLocal.contactPersonName"
                  placeholder="Nguyễn Văn A"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="t('SĐT người đại diện')"
              >
                <b-form-input
                  id="contactPersonPhone"
                  v-model="itemLocal.contactPersonPhone"
                  placeholder="0868123456"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="province"
                rules=""
              >
                <select-province
                  v-model="itemLocal.province"
                  :default-value="itemLocal.province"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="district"
                rules=""
              >
                <select-district
                  v-model="itemLocal.district"
                  :default-value="itemLocal.district"
                  :province="itemLocal.province"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="ward"
                rules=""
              >
                <select-ward
                  v-model="itemLocal.ward"
                  :default-value="itemLocal.ward"
                  :district="itemLocal.district"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="address"
                rules=""
              >
                <b-form-group
                  :label="t('Địa chỉ chi tiết')"
                >
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="isVmarket">
            <b-col>
              <b-form-group
                :label="t('Chỗ ở hiện tại')"
              >
                <b-form-input
                  id="currentAddress"
                  v-model="itemLocal.currentAddress"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="t('Ngành hàng kinh doanh')"
              >
                <b-form-input
                  id="businessDomain"
                  v-model="itemLocal.businessDomain"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                :placeholder="t('Ghi chú')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Thông tin xe') }}:
            </div>
          </b-col>
        </b-row>
        <b-row
          v-for="(eachVehicle, index) in itemLocal.vehicles"
          :id="eachVehicle.id"
          :key="eachVehicle.id"
          ref="row"
        >
          <b-col cols="3">
            <validation-provider
              #default="validationContext"
              :name="`type-${index}`"
              rules="required"
            >
              <select-vehicle-type
                v-model="eachVehicle.type"
                required
                :default-value="eachVehicle.type"
                :label="t('Loại phương tiện')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              :name="`name-${index}`"
              rules="required"
            >
              <b-form-group
                :label="t('Tên dòng xe')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên dòng xe') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  :id="`name-${index}`"
                  v-model="eachVehicle.name"
                  placeholder="Honda Airblade"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              :name="`licensePlate-${index}`"
              rules="required"
            >
              <b-form-group
                :label="t('Biển số xe')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Biển số xe') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  :id="`licensePlate-${index}`"
                  v-model="eachVehicle.licensePlate"
                  placeholder="29T2-686.68"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col
            cols="6"
            sm="5"
          >
            <validation-provider
              #default="validationContext"
              :name="`quantity-${item.id}`"
              rules="required"
            >
              <number-input
                v-model="item.quantity"
                :label="`Định mức ${index + 1}`"
                placeholder="50"
                :class="['input-number-format',{error:validationContext.errors[0] }]"
                :default-value="item.quantity"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col> -->

          <b-col
            cols="3"
            sm="2"
            class="px-0 mt-sm-2"
          >
            <b-button
              variant="outline-danger"
              class="btn px-1 btn-outline-danger ml-1 ml-sm-auto float-sm-right mr-sm-1 w-90 min-w-75"
              @click="removeVehicle(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ t('Xoá') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <div class="d-flex">
          <b-button
            variant="outline-warning"
            class="w-175"
            @click="addVehicle"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ t('Thêm xe') }}</span>
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BFormCheckbox,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectGender from '@/views/components/SelectGender.vue';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectCountry from '@/views/components/SelectCountry.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import ImageView from '@/views/components/ImageView.vue';
import SelectVehicleType from '@/views/components/SelectVehicleType.vue';
import useTenantModal from './useTenantModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    SelectVehicleType,
    SelectGender,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    SelectCountry,
    SelectDate,
    ImageView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAddLead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      addVehicle,
      onSubmit,
      removeVehicle,
      isTingTong,
      isVmarket,
      t,
      isLoading,
      onOpen,
    } = useTenantModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      isVmarket,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      addVehicle,
      t,
      removeVehicle,
      isLoading,
      onOpen,
    };
  },
};
</script>
